// src/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import UserPool from './UserPool';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useNavigate } from 'react-router-dom';
import { Link  } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


const ProtectedRoute = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userExists, setUserExists] = useState(false);
  const [incorrectPass, setIncorrectPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();


    //Signing up user
    UserPool.signUp(username, password, [], null, (err, data) => {
      if (err) {
        // Signing in user once account is confirmed
        if (err.message == "User already exists") {
          setUserExists(true);
          setErrorMsg(err.message)
          console.log("User already exits");
      } else {
        setUserExists(false);
        console.log(err);
        navigate('/confirmsignup');
        
      }
    } else {
      setUserExists(false);
      console.log(data);
      navigate('/confirmsignup');
    }
  })
    
  };

  useEffect(() => {
    console.log("User Name: ", username);
  }, [])

  return (
    <Container maxWidth="xs">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {userExists ? <Typography variant='body2' color="error">{errorMsg}</Typography> : ''}
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
          <Typography>
            Already Got an account? 
            <Link to="/">
              Sign in
            </Link>
          </Typography>
        </Box>
        
      </Box>
    </Container>
  );
};

export default ProtectedRoute;
