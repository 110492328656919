import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Stack } from "@mui/material";
import BridalParty from "../BridalParty/BridalParty";
import Topbar from "../topbar/Topbar";
import { server_url, eventInfoAPI, db_name } from "../../api";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Googlemaps2 from "../GoogleMaps/Googlemaps2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EventInfo = () => {
  //USE STATES
  const [eventInfo, setEventInfo] = useState({
    db_name: db_name,
    ceremony_location: "",
    reception_location: "",
    husband_name: "",
    wife_name: "",
    event_date: "",
    editCermLoc: true,
    editRecepLoc: true,
    editHusbandName: true,
    editWifeName: true,
    editEventDate: true,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [rawEventDate, setRawEventDate] = useState(null)
  const [editedColumn, setEditedColumn] = useState("");
  const [dataBaseTableEmpty, setDataBaseTableEmpty] = useState(true);
  const isSignedIn = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  const handleInfoChange = (event) => {
    //debugger;
    const cermLoc = event.target.value;
    const label = event.target
      .closest(".MuiFormControl-root")
      .querySelector("label").innerText;
    setEventInfo((prevVal) => ({
      ...prevVal,
      [label]: cermLoc,
    }));
    setEditedColumn(label);
  };
  const handleEdit = (event) => {
    const label = event.currentTarget.id;
    //Alllowing only one edit at a time
    setEventInfo((prevVal) => ({
      ...prevVal,
      ["editCermLoc"]: false,
      ["editRecepLoc"]: false,
      ["editEventDate"]: false,
      ["editHusbandName"]: false,
      ["editWifeName"]: false,
    }));
    setEventInfo((prevVal) => ({
      ...prevVal,
      [label]: true,
    }));
  };
  const handleSave = (event) => {
    //debugger;
    const label = event.currentTarget.id;
    setEventInfo((prevVal) => ({
      ...prevVal,
      [label]: false,
    }));
    if (dataBaseTableEmpty) {
      addEventInfo(eventInfo);
    } else {
      editEventInfo(editedColumn, eventInfo[editedColumn]);
    }
    getEventInfo(); //Getting updated data from database
  };
  const handleDateSelected = (date) => {
    debugger
    setRawEventDate(date)
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
    const dateSelected = dayjs(selectedDate).format("MMMM D, YYYY");
    console.log("Date selected: ", date.$d);
    setEventInfo((prevVal) => ({
      ...prevVal,
      ["event_date"]: dateSelected,
    }));
    if (dataBaseTableEmpty) {
      addEventInfo(eventInfo);
    } else {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      editEventInfo("event_date", formattedDate);
    }
    getEventInfo(); //Getting updated data from database
  };

  //API RESQUESTS //
  const getEventInfo = () => {
    axios
      .get(`${server_url + eventInfoAPI + "?db_name=" + db_name}`, {
        timeout: 10000, // Timeout set to 10 seconds
      })
      .then((response) => {
        //debugger;
        const data = response.data;
        console.log("Event info API: ", data);
        if (data.length === 0) {
          setDataBaseTableEmpty(true);
        } else {
          setDataBaseTableEmpty(false);
          setRawEventDate(dayjs(data[0].event_date))
          setSelectedDate(dayjs(data[0].event_date));
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the entries!", error);
      });
  };
  const editEventInfo = (columnName, eventInfo) => {
    axios
      .put(
        server_url + eventInfoAPI + "/" + columnName,
        {
          db_name: db_name,
          new_value: eventInfo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Data: ", response);
      })
      .catch((error) => {
        console.error("Error uploading file", error);
      });
  };
  const addEventInfo = (eventInfo) => {
    //debugger;
    axios
      .post(server_url + eventInfoAPI, eventInfo)
      .then((response) => {
        console.log("Response Data: ", response);
      })
      .catch((error) => {
        console.error("Error uploading file", error);
      });
  };

  // USE EFFECTS
  // useEffect(() => {
  //   const dateSelected = dayjs(selectedDate).format("MMMM D, YYYY");
  //   console.log("Date selected: ", dateSelected);
  //   setEventInfo((prevVal) => ({
  //     ...prevVal,
  //     ["event_date"]: dateSelected,
  //   }));
  //   if (dataBaseTableEmpty) {
  //     addEventInfo(eventInfo);
  //   } else {
  //     editEventInfo("event_date", selectedDate);
  //   }
  //   getEventInfo(); //Getting updated data from database
  // }, [selectedDate]);
  //Getting event info from database on page load
  useEffect(() => {
    getEventInfo();
    const signedInCache = localStorage.getItem("signedIn");
    console.log("Singed in cache: ", signedInCache);
    if (!signedInCache) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (dataBaseTableEmpty) {
      addEventInfo(eventInfo);
    } else {
      //
    }
  }, [eventInfo]);

  return (
    <Box>
      <Box>
        <Topbar />
      </Box>
      <Box paddingY={2}>
        <Typography padding={2} variant="h3">
          Event Info
        </Typography>
        <Googlemaps2 />
        <Typography padding={2} variant="h4">
          Event Date
        </Typography>
        <Stack direction="row">
          <Box>

            <Box padding={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  value={rawEventDate}
                  onChange={(newValue) => {
                    handleDateSelected(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: { display: "none" }, // Hides the label
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

        </Stack>

        <Box>
          <BridalParty />
        </Box>
        <Box>
          <Typography>Gift Registry</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EventInfo;
