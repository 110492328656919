import React, { useEffect } from "react";
import { Box, Button, Stack, Drawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, logoutUser } from "../../store/authSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import { ListItemIcon, Avatar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useMediaQuery } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import mwdLogo from "../../images/Logo_MWD.png";

const Topbar = () => {
  //const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width:768px)");

  // const handleToggleMenu = () => {
  //     setOpen(!open);
  // };

  const handleLogout = () => {
    localStorage.setItem("signedIn", false);
    dispatch(logoutUser());
    navigate("/");
  };
  const handleClick = (event) => {
    //debugger;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //console.log("Anchor Element: ", anchorEl);
  }, [anchorEl]);

  return (
    <Box sx={{ paddingBottom: 5 }}>
      <AppBar>
        <Toolbar sx={{ padding: 0 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {/* Logo */}
            <Avatar
              alt="Logo"
              src={mwdLogo}
              padding={2}
              sx={{
                mr: 2,
                display: isMobile ? "block" : "block", // Ensure it's always displayed
              }}
            />

            {/* Menu Button */}
            <Box sx={{ display: isMobile ? "block" : "block" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                size="large"
                aria-controls={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  borderRadius: "20px",
                  padding: "6px 16px",
                  display: "flex",
                  alignItems: "center",
                  textTransform: "none", // Keep "Menu" text case as is
                }}
              >
                <MenuIcon sx={{ marginRight: 1 }} />
                Menu
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="menu-appbar"
                open={open}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={handleClose}
                onClick={handleClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PermContactCalendarIcon />
                  </ListItemIcon>
                  <Button onClick={() => navigate("/entriestable")}>
                    Guest Info
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <Button onClick={() => navigate("/eventinfo")}>
                    Wedding Info
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <Button onClick={handleLogout}>Logout</Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Topbar;
