// src/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import UserPool from './UserPool';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, logoutUser } from '../store/authSlice';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AWS from 'aws-sdk';
import { AdminGetUserCommand, CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';

// Configure the AWS SDK with your region
AWS.config.update({
  region: 'ap-southeast-2', // e.g., 'us-east-1'
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});
const config = {
  region: 'ap-southeast-2', // e.g., 'us-east-1'
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
};

// Create an instance of the Cognito Identity Provider
//const cognitoIdentityServiceProvider = new AWS.CognitoIdentityProviderClient();
const client = new CognitoIdentityProviderClient(AWS.config);


const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userExists, setUserExists] = useState(0);
  const [incorrectPass, setIncorrectPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const [resetPass, setResetPass] = useState(false);
  const [stage, setStage] = useState(false); // Stage 1: Request code, Stage 2: Submit new password
  const [resetPassCode, setResetPassCode] = useState(null);

  const dispatch = useDispatch();

  

 const checkUser = () => {
  //CHecking if a user exists
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: UserPool,
  });
  console.log(username);
  cognitoUser.forgotPassword({
    onSuccess: function (data) {
      console.log('Code sent to email: ', data);
      setResetPass(false)
      setStage(true);
      //setError('');
    },
    onFailure: function (err) {
      console.error('Error: ', err);
      //setError(err.message || 'Error requesting reset code');
    },
  });

 }
  

  //console.log("Signed in Stat: ", isSignedIn);
  const handleSubmit = (e) => {
    //debugger;
    e.preventDefault();
    
    
    const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password
      });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setIncorrectPass(false);
          setUserExists(0);
          console.log("onSuccess: ", data);
          //set signed in state
          dispatch(loginUser(username));
          localStorage.setItem('signedIn', true);
          //Take user to rsvp page
          navigate('/entriestable');
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          setIncorrectPass(true);
          setErrorMsg(err.message);

        },
        newPasswordRequired: (data) => {
          console.log("newPassworddRequired: ", data);
        }
      });
  };

  const handleForgotPass = () => {
    //const userExists = await checkUser(username);
    console.log('USername: ', username);
    setResetPass(true);
    // const user = new CognitoUser({
    //   Username: username,
    //   Pool: UserPool,
    // });
    // user.forgotPassword({
    //   onSuccess: () => {
    //     console.log("Reset code sent");
    //   },
    //   onFailure: err => {
    //     setErrorMsg(err);
    //     console.log("Error sending reset code: ", err);
    //   }
    // });
  }

  const handleNewPassword = () => {
    // Check if passwords match
    if (password !== confirmPassword) {
      setIncorrectPass(true)
      setErrorMsg('Passwords do not match');
      return;
    }
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    user.confirmPassword(resetPassCode, password, {
      onSuccess: () => {
        setStage(false);
        setIncorrectPass(false);
        console.log("Password Reset!");
        navigate('/');
      },
      onFailure: (err) => {
        setIncorrectPass(true);
        setErrorMsg(err);
        console.log("Error with reset code: ", err);
      }
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
        //console.log("Is singed in: ", isSignedIn);
    }
    console.log('Singed in: ' + isAuthenticated);
  }, [isAuthenticated])

  return (
    <Container maxWidth="xs">
    {!resetPass && !stage && (
        <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {incorrectPass ? <Typography variant='body2' color="error">{errorMsg}</Typography> : ''}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Login
        </Button>
          <Typography>
            Don't have an account? 
            <Link to="/protectedRoute">
              Sign up
            </Link>
          </Typography>
          <Typography>
            Forgot passowrd? 
            <Button onClick={handleForgotPass}>
              reset password
            </Button>
          </Typography>
      </Box>
    )}
    {resetPass && (
      <Box>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        
        {incorrectPass ? <Typography variant='body2' color="error">{errorMsg}</Typography> : ''}
        <Button onClick={checkUser}>
              Send Code
        </Button>
      </Box>
    )}
    {stage && (
      <Box>
      <Typography component="h1" variant="h5">
        New Password
      </Typography>
      <TextField
            label="Verification Code"
            type="text"
            fullWidth
            margin="normal"
            value={resetPassCode}
            onChange={(e) => setResetPassCode(e.target.value)}
            required
          />
      <TextField
        margin="normal"
        type="password"
        required
        fullWidth
        label="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
      {incorrectPass ? <Typography variant='body2' color="error">{errorMsg}</Typography> : ''}
      <Button onClick={handleNewPassword}>
            Reset Password
      </Button>
    </Box>
    )}
    </Container>
  );
};

export default Login;
