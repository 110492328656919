import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  Stack,
  Typography,
  TableSortLabel,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "./EventAdmin.scss";
import Footer from "../footer/Footer";
import Topbar from "../topbar/Topbar";
import bgimage from "../../images/bgpattern.jpg";
import { server_url, getGuestInfoAPI, postGuestData, db_name } from "../../api";
import { styled } from "@mui/system";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { isMobileDevice } from "../DeviceRecognition/DeviceRecognition";
import debounce from "lodash.debounce";

const EntriesTable = () => {
  //State variables
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    db_name: db_name,
    first_name: "",
    last_name: "",
    inv_code: "",
    email: "",
    phoneNo: "",
    tableNo: "",
    rsvp: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [editHistory, setEditHistory] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [ageValInvalid, setAgeValInvalid] = useState(false); //preventing incorrect value entry format
  const [phoneNoValInvalid, setPhoneNoValInvalid] = useState(false); //preventing incorrect value entry format
  const [tableNoValInvalid, setTableNoValInvalid] = useState(false); //preventing incorrect value entry format
  const isSignedIn = useSelector((state) => state.auth.isAuthenticated);
  const userName = useSelector((state) => state.auth.user);
  const [tableRows, setTableRows] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [addEntryError, setAddEntryError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadExcelErr, setUploadExcelErr] = useState(null);

  const navigate = useNavigate();

  // CONSTANTS
  const theme = useTheme();
  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 150 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 150 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "inv_code",
      headerName: "Invitation Code",
      flex: 1,
      width: isMobile ? 100 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 150 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "phoneNo",
      headerName: "Phone No.",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 150 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "tableNo",
      headerName: "Table No.",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 100 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "rsvp",
      headerName: "RSVP",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 150 : undefined,
      headerAlign: "center",
      align: "center",
      editable: true,

      renderEditCell: (params) => (
        <Select
          fullWidth
          className="custom-select" // Apply the CSS class
          value={params.value}
          onChange={(event) =>
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value,
            })
          }
        >
          <MenuItem value={"Going"}>Going</MenuItem>
          <MenuItem value={"Not Going"}>Not Going</MenuItem>
          <MenuItem value={"Tenative"}>Tenativ</MenuItem>
        </Select>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 100 : undefined,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box>
          <Button
            id={"deleteEntryButton" + params.id}
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.id)}
          >
            Delete
          </Button>
          <CircularProgress
            size={20}
            id={"deleteEntryBuffer" + params.id}
            style={{
              display: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Box>
      ),
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    //CHECK TO PRECENT INCORRECT VALUE ENTRY FORMAT (Numbers in this case)
    setAgeValInvalid(false);
    setPhoneNoValInvalid(false);
    setTableNoValInvalid(false);
    // if (name === "inv_code") {
    //   if (/^\d*$/.test(value)) {
    //     //Checking if value is a number
    //     setAgeValInvalid(false);
    //     setNewEntry({ ...newEntry, [name]: value });
    //   } else {
    //     setAgeValInvalid(true); //preventing incorrect value entry format
    //     setErrMsg("Please enter a number");
    //   }
    // } else
    if (name === "phoneNo") {
      if (/^\d*$/.test(value)) {
        //Checking if value is a number
        setPhoneNoValInvalid(false);
        setNewEntry({ ...newEntry, [name]: value });
      } else {
        setPhoneNoValInvalid(true); //preventing incorrect value entry format
        setErrMsg("Please enter a number");
      }
    } else if (name === "tableNo") {
      if (/^\d*$/.test(value)) {
        //Checking if value is a number
        setTableNoValInvalid(false); //preventing incorrect value entry format
        setNewEntry({ ...newEntry, [name]: value });
      } else {
        setTableNoValInvalid(true); //preventing incorrect value entry format
        setErrMsg("Please enter a number");
      }
    } else {
      setNewEntry({ ...newEntry, [name]: value });
    }
  };

  const handleAddEntry = () => {
    //debugger;
    if (newEntry.first_name && newEntry.last_name && newEntry.email) {
      setAddEntryError(false);
      axios
        .post(`${server_url + getGuestInfoAPI}`, newEntry)
        .then((response) => {
          console.log(response.data);
          getData();
          setNewEntry({
            db_name: db_name,
            first_name: "",
            last_name: "",
            inv_code: "",
            email: "",
            phoneNo: "",
            tableNo: "",
            rsvp: "",
          });
          const time_stamp = new Date();
          setEditHistory({
            time_stamp: time_stamp,
            edit_info: `new entry made with first_name ${newEntry.first_name} and email ${newEntry.email}`,
          });
        })
        .catch((error) => {
          console.error("There was an error posting the data!", error);
        });
    } else {
      setAddEntryError(true);
      console.error("Entry Error: Require first name, last name and email");
      setErrMsg("First name, last name and email required");
    }
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedRow(entries.find((entry) => entry.id === index));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedRow({ ...editedRow, [name]: value });
  };

  const handleSaveEdit = (editingIndex2, editedRow2) => {
    axios
      .put(`${server_url + getGuestInfoAPI}/${editingIndex2}`, editedRow2)
      .then((response) => {
        console.log("Success:", response.data);
        getData();
        //setEditingIndex(null);
        //const time_stamp = new Date();
        // setEditHistory({
        //   time_stamp: time_stamp,
        //   edit_info: editedRow2,
        // });
      })
      .catch((error) => {
        console.error("Entries PUT API Error:", error);
      });
  };

  const handleDelete = (index) => {
    //Turning buffer on
    document.getElementById("deleteEntryButton" + index).style.display = "none";
    document.getElementById("deleteEntryBuffer" + index).style.display = "flex";
    const deletedRow = entries.filter((row) => row.id === index);

    axios
      .delete(`${server_url + getGuestInfoAPI}/${index}`, {
        data: {
          db_name: db_name,
        },
      })
      .then((response) => {
        //Turning buffer off
        document.getElementById("deleteEntryButton" + index).style.display =
          "flex";
        document.getElementById("deleteEntryBuffer" + index).style.display =
          "none";
        console.log(response.data);
        getData();
        const time_stamp = new Date();
        setEditHistory({
          time_stamp: time_stamp,
          edit_info: `deleted ${deletedRow[0].first_name} with email ${deletedRow[0].email}`,
        });
      })
      .catch((error) => {
        console.error("There was an error deleting the entry!", error);
      });
  };

  const getData = () => {
    //TUrning buffer animation on
    document.getElementById("loadingEntriesBuffer").style.display = "flex";
    axios
      .get(`${server_url + getGuestInfoAPI + "?db_name=" + db_name}`, {
        timeout: 10000, // Timeout set to 10 seconds
      })
      .then((response) => {
        document.getElementById("loadingEntriesBuffer").style.display = "none";
        setEntries(response.data);
        //debugger
        const data = response.data;
        const rows = [];
        data.map((i) => {
          rows.push({
            id: i.id,
            db_name: db_name,
            first_name: i.first_name,
            last_name: i.last_name,
            inv_code: i.inv_code,
            email: i.email,
            phoneNo: i.phoneNo,
            tableNo: i.tableNo,
            rsvp: i.rsvp,
          });
        });
        console.log(rows);
        setTableRows(rows);
      })
      .catch((error) => {
        console.error("There was an error fetching the entries!", error);
      });
  };
  //Adding editing history
  const handleEditHistory = () => {
    //debugger;
    axios
      .post(`${server_url}/edit-history`, editHistory)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error posting the data!", error);
      });
  };

  //Sorting table Functionality
  const handleSortRequest = (property) => {
    //debugger;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const sortData = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortedData = sortData(entries, getComparator(order, orderBy));

  // HANDLE CHANGE IN TABLE ROW edits
  const tableRowUpdate = (newRow) => {
    const rowId = Number(newRow.id); // Get the ID of the row being edited
    console.log("Editing row with ID:", rowId);
    console.log("New row: ", newRow);
    //debugger;
    handleSaveEdit(rowId, newRow);
  };
  const handleProcessRowUpdate = (newRow) => {
    const rowId = newRow.id; // Get the ID of the row being edited
    console.log("Editing row with ID:", rowId);

    const updatedRow = { ...newRow, isNew: false, db_name: db_name };
    setTableRows(
      tableRows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    handleSaveEdit(rowId, updatedRow);
    return updatedRow;
  };

  //SEARCH TEXT FEATURE //
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
  };

  // Debounce search
  const debouncedSearch = debounce((search) => {
    if (searchText === null) {
      // If search is empty, show all rows
      setFilteredRows(entries);
    } else {
      const lowercasedSearch = search.toLowerCase();
      const newFilteredRows = entries.filter((row) =>
        Object.values(row).some((val) =>
          (val ? val.toString().toLowerCase() : "").includes(lowercasedSearch)
        )
      );
      setFilteredRows(newFilteredRows);
    }
  }, 300);

  // USE EFFECTS //
  useEffect(() => {
    getData();
    const signedInCache = localStorage.getItem("signedIn");
    console.log("Singed in cache: ", signedInCache);
    if (!signedInCache) {
      navigate("/");
    }
    // recognising device type
    setIsMobile(isMobileDevice());
  }, []);
  // For search debouncing feature
  useEffect(() => {
    debouncedSearch(searchText);
    console.log(typeof searchText);
  }, [searchText]);

  // Define custom styles for the table header cells
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    align: "center",
  }));

  // Function to export table data
  const exportToExcel = (data, fileName) => {
    // Define the order of the columns here
    const columnOrder = [
      { header: "ID", key: "id", width: 10 },
      { header: "First Name", key: "first_name", width: 20 },
      { header: "Last Name", key: "last_name", width: 20 },
      { header: "Invite Code", key: "inv_code", width: 15 },
      { header: "Email", key: "email", width: 25 },
      { header: "Phone No", key: "phoneNo", width: 15 },
      { header: "Table No", key: "tableNo", width: 10 },
      { header: "RSVP", key: "rsvp", width: 10 },
    ];

    // Step 1: Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Step 2: Add columns to the worksheet
    worksheet.columns = columnOrder;

    // Step 3: Add the data rows
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Step 4: Add a hidden row with drop-down values (Yes, No, Maybe)
    const dropdownRow = worksheet.addRow([
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Yes",
      "No",
      "Maybe",
    ]);
    dropdownRow.hidden = true; // Hide this row so users don’t see it

    // Step 5: Add data validation (drop-down) to the 'RSVP' column
    const rsvpColumnIndex =
      columnOrder.findIndex((col) => col.key === "rsvp") + 1;

    worksheet.getColumn(rsvpColumnIndex).eachCell((cell, rowNumber) => {
      if (rowNumber > 1) {
        // Skip the header row
        cell.dataValidation = {
          type: "list",
          allowBlank: false,
          formula1: `Sheet1!$H$${dropdownRow.number}:$J$${dropdownRow.number}`, // Use the hidden row for dropdown
          showDropDown: true,
          errorStyle: "warning",
          errorTitle: "Invalid RSVP",
          error: "Please select Yes, No, or Maybe",
          showErrorMessage: true,
        };
      }
    });

    // Step 6: Generate the Excel file and trigger the download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const dataBlob = new Blob([buffer], {
        type: "application/octet-stream",
      });
      saveAs(dataBlob, `${fileName}.xlsx`);
    });
  };

  // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataBlob = new Blob([excelBuffer], {
  //     type: "application/octet-stream",
  //   });
  //   saveAs(dataBlob, `${fileName}.xlsx`);
  // };
  // FUNTIONS TO UPLOAD EXCEL GUEST DATA //
  const requiredColumns = [
    "id",
    "first_name",
    "last_name",
    "inv_code",
    "email",
    "phoneNo",
    "tableNo",
    "rsvp",
  ]; // Define the required columns
  const handleFileUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target?.result;
        if (typeof binaryStr === "string") {
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Check if the required columns are present
          const headers = sheetData[0];
          const missingColumns = requiredColumns.filter(
            (col) => !headers.includes(col)
          );

          if (missingColumns.length > 0) {
            console.log(
              `The following columns are missing: ${missingColumns.join(", ")}`
            );
            setUploadExcelErr(
              `The following columns are missing: ${missingColumns.join(", ")}`
            );
          } else {
            debugger;
            setUploadExcelErr(null);
            const rows = sheetData.slice(1);
            const formattedData = rows.map((row) => {
              const rowData = {};
              headers.forEach((header, index) => {
                rowData[header] = row[index];
              });
              return rowData;
            });
            // ADDING EXCEL DATAT, after checking if entries exsit //
            formattedData.forEach((row, index) => {
              debugger;
              const entriesRow = entries.filter((item) => item.id === row.id);
              // CHECKING IF ANY INVALID DATA FORMAT IS IN ROW //

              if (typeof Number(row.phoneNo) !== "number") {
                setUploadExcelErr(
                  "There is an invalid phone number entry in row " + row.id
                );
                return;
              }
              if (entriesRow.length) {
                //handleSaveEdit(row.id, row) // editing existing row entry
              } else {
                const newentry = { ...row, db_name: db_name };
                console.log("Posting row: ", newentry);
                postGuestData(newentry); //adding new row entry
                setTimeout(getData(), 1000);
              }
            });
            console.log("Excel uploaded data: ", formattedData);
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };
  // Handling upload excel button clicked
  const handleUploadClicked = () => {
    document.getElementById("excel-input").click();
  };

  return (
    <Box>
      <Topbar />
      <Grid
        container
        sx={{
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
          borderRadius: 2,
        }}
      >
        <Grid item sx={12} sm={12} md={12}>
          <Box sx={{ padding: 3 }}>
            <Typography
              style={{ fontWeight: "bold", textAlign: "center" }}
              variant="h3"
              padding={5}
            >
              Event Admin
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box
            sx={{
              justifyContent: "start",
              width: "fit-content",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                textAlign: "left",
              }}
              variant="h4"
              padding={5}
            >
              Guest Details
            </Typography>
          </Box>
        </Grid>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Search"
          value={searchText}
          onChange={handleSearchChange}
        />
        {
          <CircularProgress
            id="loadingEntriesBuffer"
            sx={{ display: "none" }}
          />
        }
        <Grid item xs={12} sm={12} paddingBottom={2}>
          <DataGrid
            rows={searchText === null || "" ? entries : filteredRows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            processRowUpdate={handleProcessRowUpdate}
            className="custom-header"
          />
        </Grid>
        <Grid item sx={12} sm={12} md={12}>
          <Box style={{ marginTop: "20px", paddingBottom: 10 }}>
            <Stack direction={isMobile ? "column" : "row"}>
              <TextField
                label="First Name"
                name="first_name"
                value={newEntry.first_name}
                onChange={handleInputChange}
                style={{ margin: "10px" }}
              />
              <TextField
                label="Last Name"
                name="last_name"
                value={newEntry.last_name}
                onChange={handleInputChange}
                style={{ margin: "10px" }}
              />
              <Stack>
                <TextField
                  label="Inivation Code"
                  name="inv_code"
                  value={newEntry.inv_code}
                  onChange={handleInputChange}
                  style={{ margin: "10px" }}
                  error={ageValInvalid === true}
                />
                {ageValInvalid && (
                  <Typography
                    color="error"
                    sx={{
                      fontSize: isMobile ? "10px" : "10px",
                      marginLeft: isMobile ? "2vw" : "1vw",
                      marginTop: "-1vw",
                    }}
                  >
                    {errMsg}
                  </Typography>
                )}
              </Stack>

              <TextField
                label="Email"
                name="email"
                value={newEntry.email}
                onChange={handleInputChange}
                style={{ margin: "10px" }}
              />
              <Stack>
                <TextField
                  label="Phone Number"
                  name="phoneNo"
                  value={newEntry.phoneNo}
                  onChange={handleInputChange}
                  style={{ margin: "10px" }}
                />
                {phoneNoValInvalid && (
                  <Typography
                    color="error"
                    sx={{
                      fontSize: isMobile ? "10px" : "10px",
                      marginLeft: isMobile ? "2vw" : "1vw",
                      marginTop: "-1vw",
                    }}
                  >
                    {errMsg}
                  </Typography>
                )}
              </Stack>
              <Stack>
                <TextField
                  label="Table Number"
                  name="tableNo"
                  value={newEntry.tableNo}
                  onChange={handleInputChange}
                  style={{ margin: "10px" }}
                />
                {tableNoValInvalid && (
                  <Typography
                    color="error"
                    sx={{
                      fontSize: isMobile ? "10px" : "10px",
                      marginLeft: isMobile ? "2vw" : "1vw",
                      marginTop: "-1vw",
                    }}
                  >
                    {errMsg}
                  </Typography>
                )}
              </Stack>

              <Button
                variant="contained"
                sx={{
                  marginRight: !isMobile && -10,
                }}
                style={{ marginTop: 5 }}
                onClick={handleAddEntry}
              >
                Add Entry
              </Button>
            </Stack>
          </Box>
          <Box justifyContent="center" paddingBottom={5}>
            {addEntryError && (
              <Typography color="error" textAlign="center">
                {errMsg}
              </Typography>
            )}
          </Box>
        </Grid>
        <Box paddingBottom={10}>
          <Stack spacing={2} direction="row">
            <input
              type="file"
              id="excel-input"
              accept=".xlsx, .xls"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadClicked}
            >
              Upload Excel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToExcel(entries, "table_data", requiredColumns)
              }
            >
              Download as Excel
            </Button>
          </Stack>
          <Typography color="error">{uploadExcelErr}</Typography>
        </Box>
        <Grid item sx={12} sm={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EntriesTable;
