import axios from "axios";

export const server_url2 = 'http://localhost:5000';
export const server_url3 = 'https://mathew-ruth.arackalweb.shop:5001';
export const server_url = 'https://i8zf11lkua.execute-api.ap-southeast-2.amazonaws.com/rsvpwedadmin'
export const eventInfoAPI = '/event_info/mathew-ruth';
export const getGuestInfoAPI = '/entries/mathew-ruth'
export const bridalPartyAPI = '/bridalparty/mathew-ruth';
export const s3BucketUpload = '/upload/mathew-ruth';
export const s3BucketDownload= '/download/mathew-ruth';
export const db_name = 'daniel_susan';

export const branchName = process.env.REACT_APP_BRANCH_NAME;
console.log('Branch name: ', branchName);

export const postGuestData = (newEntry) => {
    axios
      .post(`${server_url + getGuestInfoAPI}`, newEntry)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error posting the data!", error);
      });
  };