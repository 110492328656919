import React, { useState } from 'react';
import AWS from 'aws-sdk';

// Initialize AWS with your credentials and region
AWS.config.update({
  accessKeyId: 'AKIAQ3EGR7G26ELPPWPE',
  secretAccessKey: 'kqnGu4d7j6ZNUOv/1RXhaqr/QRIPPWBPsDzD1+Am',
  region: 'ap-southeast-2', 
});

const s3 = new AWS.S3();

export const s3BucketApi = (file, onProgress) => {
    console.log('Env Variables: ', process.env.AWS_ACCESS_KEY)
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: 'wed-rsvp-admin', // Your S3 bucket name
      Key: file.name,             // File name
      Body: file,                 // File object
      ContentType: file.type,     // Content type (MIME type)
      ACL: 'public-read',         // Set file access permissions
    };

    const upload = s3.upload(params);

    // Track the progress of the upload
    upload.on('httpUploadProgress', (progress) => {
      const percentage = Math.round((progress.loaded / progress.total) * 100);
      onProgress(percentage); // Update progress value
    });

    // Handle upload completion
    upload.send((err, data) => {
      if (err) {
        reject(err); // Handle error during upload
      } else {
        resolve(data.Location); // Return the image URL
      }
    });
  });
};

