
import React, { useEffect, useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from './UserPool';
import { useNavigate } from 'react-router-dom';

const ConfirmSignup = () => {
  const [code, setCode] = useState('');
  const userName = useSelector(state => state.auth.user);
  const navigate = useNavigate();
  const user = new CognitoUser({
    Username: userName,
    Pool: UserPool,
  });
  

  const confirmSignUp = (code) => {
    user.confirmRegistration(code, true, (err, result) => {
        if (err){
            console.log(err);
        } else {
            console.log(result);
            navigate('/login');
        }
      })
    
  };

  const handleResendCode = async () => {
    try {
      await resendSignUpCode(userName);
      console.log('Verification code resent successfully!');
      // Inform user that a new code has been sent
    } catch (error) {
      console.error('Resend error:', error);
      // Handle resend error (e.g., display error message to user)
    }
  }

  const handleVerifyClick = () => {
    confirmSignUp(code);
  }

  useEffect(() => {
    console.log("User Name: ", userName);
  }, [])
  return (
    <Box>
        <Typography component="h1" variant="h5">
          Enter verification code
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="verifyCode"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button onClick={handleVerifyClick}>
            Verify
        </Button>
        <Button onClick={handleResendCode}>
            Re-send code
        </Button>
    </Box>
  );
};

export default ConfirmSignup;