// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'poppins',
  },
  palette: {
    primary: {
      main: '#5e8a5c', // Primary color
    },
    secondary: {
      main: '#89ab87', // Secondary color
    },
    background: {
      default: '#f5f5f5', // Background color
      paper: '#ffffff', // Paper color
    },
    text: {
      primary: '#000000', // Text color
      secondary: '#354534', // Secondary text color
    },
  },
});

export default theme;
