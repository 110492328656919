// src/App.js
import React, { useState } from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import GuestRSVP from './components/GuestRSVP';
import ConfirmSignup from './components/ConfirmSignup.js';
import Login from './components/Login';
import { Container } from '@mui/material';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './store/store.js';
import { createBrowserRouter, RouterProvider, BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound from './components/PageNotFound.tsx';
import EntriesTable from './components/EventAdmin/EntriesTable.js';
import EventInfo from './components/EventInfo/EventInfo.js';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme/theme.js';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Container>
          <BrowserRouter>
            <Routes>
              <Route path='protectedRoute' element={<ProtectedRoute/>} />
              <Route path='/confirmsignup' element={<ConfirmSignup/>} />
              <Route path='/entriestable' element={<EntriesTable/>} />
              <Route path='/eventinfo' element={<EventInfo/>} />
              <Route index element={<Login/>} />
              <Route path='/rsvp' element={<GuestRSVP/>} />
              <Route path='*' element={<PageNotFound/>} />
            </Routes>
          </BrowserRouter>
        </Container>

    </ThemeProvider>
    
    
  );
}

export default App;
