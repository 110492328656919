// src/GuestRSVP.js
import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';;

const GuestRSVP = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [description, setDescription] = useState('');
  const isSignedIn = useSelector(state => state.login.value);
  // const userName = useSelector(state => state.userName.isSignedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const eventData = { name, email, eventDate, description };
    console.log(eventData);
    alert('Event submitted successfully');
    // Reset form
    setName('');
    setEmail('');
    setEventDate('');
    setDescription('');
  };

  const handleLogout = () => {
    navigate('/');

  }
  useEffect(() => {
    console.log("Singed In Status: ", isSignedIn);
  }, [])

  return (
    <Container maxWidth="sm">
      <AppBar position="fixed" sx={{ top: 0, right: 0, left: 'auto', backgroundColor: '#2a6d82', boxShadow: 'none' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography variant="h6" noWrap component="div" sx={{ color: 'black', marginRight: 1 }}>
          RSVP App
        </Typography>
        <IconButton onClick={handleLogout}>
          <LogoutIcon csx={{ color: '#2a6d82' }}/>
        </IconButton>
      </Toolbar>
    </AppBar>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Event Entry Form
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Event Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit Event
        </Button>
      </Box>
    </Container>
  );
};

export default GuestRSVP;
