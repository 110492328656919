import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';
import './Footer.css'


const Footer = () => {

  return (
    <footer className='footer'>
      <Container maxWidth="lg" style={{backgroundColor: '#c9c6bd'}}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <ul>
              <li>
                <Link href="#" variant="subtitle1" color="textSecondary" className='link'>
                  About Us
                </Link>
              </li>
              <li>
                <Link href="#" variant="subtitle1" color="textSecondary" className='link'>
                  Contact
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Resources
            </Typography>
            <ul>
              <li>
                <Link href="#" variant="subtitle1" color="textSecondary" className='link'>
                  Help Center
                </Link>
              </li>
              <li>
                <Link href="#" variant="subtitle1" color="textSecondary" className='link'>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Social
            </Typography>
            <ul>
              <li>
                <Link href="#" variant="subtitle1" color="textSecondary" className='link'>
                  Facebook
                </Link>
              </li>
              <li>
                <Link href="#" variant="subtitle1" color="textSecondary" className='link'>
                  Instagram
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '1rem' }}>
          {'© '}
          <Link color="inherit" href="https://yourcompany.com/">
            Mango Web Designs
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
