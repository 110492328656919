import React, { useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import { server_url, bridalPartyAPI, s3BucketUpload, db_name } from "../../api";
import { useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { s3BucketApi } from "../../service/s3BucketApi";

const BridalParty = () => {
  // USESTATES
  const [file, setFile] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [bridalParty, setBridalParty] = useState([]);
  const [groomsMen, setGroomsMen] = useState([]);
  const [bridesMaids, setBridesMaids] = useState([]);
  const [sqlData, setSqlData] = useState([]);
  const [editName, setEditName] = useState(true);
  const [editHistory, setEditHistory] = useState({});
  const [imageUploading, setImageUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [addingGroomsmanBuffer, setAddingGroomsmanBuffer] = useState(false);
  const [imgUploadProgress, setImgUploadProgress] = useState(0); //Image Upload progress state
  const [editedBridalParty, setEditedBridalParty] = useState([
    {
      id: 0,
      name: "",
      img: "",
    },
  ]);
  //Checking if device is mobile
  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 768);

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
  };
  const isMobile = useIsMobile();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  //API REUQESTS //
  const handleGetBridalParty = () => {
    //Turn on buffer
    document.getElementById('BridalPartyLoad').style.display = 'flex'
    axios
      .get(`${server_url + bridalPartyAPI + "?db_name=" + db_name}`, {
        timeout: 10000, // Timeout set to 10 seconds
      })
      .then((response) => {
        //Turn off buffer
    document.getElementById('BridalPartyLoad').style.display = 'none'
        //debugger;
        const data = response.data;
        setSqlData(response.data);
        console.log(response.data);
        const bridalPartyData = [];
        data.map((item) => {
          const bridalPartyNew = {
            id: item.id,
            db_name: db_name,
            party: item.party,
            img: item.image,
            name: item.name,
            editname: false,
            changeimg: false,
          };
          bridalPartyData.push(bridalPartyNew);
        });
        setBridalParty(bridalPartyData);
      })
      .catch((error) => {
        console.error("There was an error fetching the entries!", error);
      });
  };
  const handlePostBridalParty = (bridalParty) => {
    //debugger;
    setAddingGroomsmanBuffer(true);
    axios
      .post(`${server_url + bridalPartyAPI}`, bridalParty)
      .then((response) => {
        document.getElementById("bridesmaidsBox").style.display = "none";
        document.getElementById("groomsmanBox").style.display = "none";
        setAddingGroomsmanBuffer(false);
        console.log(response.data);
        handleGetBridalParty();
      })
      .catch((error) => {
        document.getElementById("bridesmaidsBox").style.display = "none";
        document.getElementById("groomsmanBox").style.display = "none";
        console.error("There was an error posting the data!", error);
      });
  };
  const handlePutBridalParty = (editingIndex, editedRow) => {
    const bufferId = "bridalPartyNameChangeBuffer" + editingIndex;
    console.log("Buffer id: ", bufferId);
    document.getElementById(bufferId).style.display = "flex";
    axios
      .put(`${server_url + bridalPartyAPI}/${editingIndex}`, editedRow)
      .then((response) => {
        //TURNING BUFFER OFF
        document.getElementById(bufferId).style.display = "none";
        const data = response.data;
        console.log("Success:", response.data);
        handleGetBridalParty();

        const time_stamp = new Date();
        setEditHistory({
          time_stamp: time_stamp,
          edit_info: editedRow,
        });
      })
      .catch((error) => {
        //TURNING BUFFER OFF
        document.getElementById(bufferId).style.display = "none";
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  };
  const handleDeleteBridalParty = (id) => {
    document.getElementById("bridalPartyDelete" + id).style.display = "none";
    document.getElementById("bridalPartyDeleteBuffer" + id).style.display =
      "flex";

    console.log("Delete id: ", id);
    axios
      .delete(`${server_url + bridalPartyAPI}/${id}`, {
        data: {
          db_name: db_name,
        },
      })
      .then((response) => {
        document.getElementById("bridalPartyDelete" + id).style.display =
          "flex";
        document.getElementById("bridalPartyDeleteBuffer" + id).style.display =
          "none";
        console.log(response.data);
        handleGetBridalParty();
        // const time_stamp = new Date();
        // setEditHistory({
        //   time_stamp: time_stamp,
        //   edit_info: `deleted ${deletedRow[0].name} with email ${deletedRow[0].email}`,
        // });
      })
      .catch((error) => {
        console.error("There was an error deleting the entry!", error);
      });
  };

  const handleAddGroomsmen = () => {
    //debugger;
    document.getElementById("groomsmanBox").style.display = "flex";
    const firstEntry = bridalParty.length;
    const bridalPartyNew = {
      id: firstEntry ? bridalParty[bridalParty.length - 1].id + 1 : 1,
      db_name: db_name,
      party: "groomsman",
      img: "",
      name: "",
      editname: true,
      changeimg: true,
    };
    // setBridalParty([
    //   ...bridalParty,
    //   bridalPartyNew,
    // ]);
    handlePostBridalParty(bridalPartyNew);
  };
  const handleAddBridesmaids = () => {
    //debugger;
    document.getElementById("bridesmaidsBox").style.display = "flex";
    const firstEntry = bridalParty.length;
    const bridalPartyNew = {
      id: firstEntry ? bridalParty[bridalParty.length - 1].id + 1 : 1,
      db_name: db_name,
      party: "bridesmaid",
      img: "",
      name: "",
      editname: true,
      changeimg: true,
    };
    handlePostBridalParty(bridalPartyNew);
  };
  // Function to handle name change
  const handleEditImage = (index) => {
    // setting the clicked image to edit mode and turning off edit mode for the other pictures
    setGroomsMen(
      groomsMen.map((item) =>
        index === item.id
          ? { ...item, changeimg: !item.changeimg }
          : { ...item, changeimg: false }
      )
    );
    setBridesMaids(
      bridesMaids.map((item) =>
        index === item.id
          ? { ...item, changeimg: !item.changeimg }
          : { ...item, changeimg: false }
      )
    );
  };
  const handleImgChange = (index, event) => {
    setImageUploading(true);
    const selectedFile = event.target.files[0];
    const formData = new FormData();

    formData.append("image", selectedFile);
    setFileUrl(selectedFile);
    //console.log("Upload Image Object: ", selectedFile);

    // axios
    //   .post(server_url + s3BucketUpload, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then((response) => {
    //     setImageUploading(false);
    //     const newUrl = response.data.url;
    //     debugger;
    //     const editedRow = bridalParty.filter((party) => party.id == index);
    //     editedRow[0].img = newUrl;
    //     console.log("Response Data: ", response);
    //     handlePutBridalParty(index, editedRow);
    //   })
    //   .catch((error) => {
    //     setImageUploading(false);
    //     console.error("Error uploading file", error);
    //   });
  };
  // Image upload button pressed
  const handleImageUpload = async (event) => {
    const id = event.currentTarget.id;
    if (fileUrl) {
      try {
        //Upload the file to S3 and track progress
        console.log();

        const url = await s3BucketApi(fileUrl, (progress) => {
          setImgUploadProgress(progress); // Update progress state
        });
        //setImageUrl(url); // Set the image URL
        console.log("File uploaded successfully!");
        const newUrl = url;
        console.log("URL: ", url);
        //debugger;
        const editedRow = bridalParty.filter((party) => party.id == id);
        editedRow[0].img = newUrl;
        console.log("edited row: ", editedRow);
        handlePutBridalParty(id, editedRow);
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file.");
      }
    } else {
      console.error("File not selected yet");
    }
  };
  // Function to handle name change
  const handleNameChange = (id, event) => {
    //debugger;
    const newName = event.target.value;
    //setGroomsMen(groomsMen.map((item) =>
    //   id === item.id ? { ...item, name: newName } : item
    // ));
    const editedRow = bridalParty.filter((party) => party.id == id);
    editedRow[0].name = newName;
    console.log("Edited row: ", editedRow);
    setEditedBridalParty(editedRow);
  };
  // Function to handle save action
  const handleEditName = (index) => {
    setGroomsMen(
      groomsMen.map((item) =>
        index === item.id ? { ...item, editname: true } : item
      )
    );
    setBridesMaids(
      bridesMaids.map((item) =>
        index === item.id ? { ...item, editname: true } : item
      )
    );
  };
  // Function to handle save action
  const handleSave = (index) => {
    //debugger
    //document.getElementById('bridalPartyNameChangeBuffer'+index).style.display = 'flex'
    handlePutBridalParty(index, editedBridalParty);
    setGroomsMen(
      groomsMen.map((item) =>
        index === item.id ? { ...item, editname: false } : item
      )
    );
    setBridesMaids(
      bridesMaids.map((item) =>
        index === item.id ? { ...item, editname: false } : item
      )
    );
  };

  const handleDeleteGroomsmen = (index) => {
    setBridalParty(bridalParty.filter((_, i) => i !== index));
  };

  //Load Bridal Party Data on page load
  useEffect(() => {
    handleGetBridalParty();
  }, []);

  useEffect(() => {
    console.log("Bridal Party: ", bridalParty);
    const groomsMenUpdated = bridalParty.filter(
      (party) => party.party === "groomsman"
    );
    setGroomsMen(groomsMenUpdated);
    const bridesMaidUpdated = bridalParty.filter(
      (party) => party.party === "bridesmaid"
    );
    setBridesMaids(bridesMaidUpdated);
  }, [bridalParty]);

  useEffect(() => {
    console.log("Brides Men: ", bridesMaids);
  }, [bridesMaids]);

  return (
    <Box padding={1}>
      <Container>
        <Typography variant="h4" gutterBottom>
          Bridal Party
        </Typography>
        <Box>
          <Typography variant="h5" gutterBottom>
            Groomsmen
          </Typography>
          {<CircularProgress id='BridalPartyLoad' sx={{display: 'none'}}/>}
          <Stack direction={isMobile ? "column" : "row"}>
            {groomsMen.map((groomsmen, index) => {
              return (
                <Box
                  sx={{
                    outline: "solid",
                    padding: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={groomsmen.img}
                    alt={groomsmen.name}
                    sx={{ width: 100, height: 100, mb: 2 }}
                  />

                  {groomsmen.changeimg && (
                    <Stack direction={isMobile ? "column" : "row"}>
                      {imgUploadProgress > 0 && imgUploadProgress < 100 && (
                        <p>{imgUploadProgress}%</p>
                      )}
                      <TextField
                        size="small"
                        variant="standard"
                        sx={{ mb: 2, textAlign: "center"}}
                        type="file"
                        onChange={(event) =>
                          handleImgChange(groomsmen.id, event)
                        }
                      />
                      <Button id={groomsmen.id} onClick={handleImageUpload}>
                        Upload
                      </Button>
                    </Stack>
                  )}
                  <Button onClick={() => handleEditImage(groomsmen.id)}>
                    {groomsmen.changeimg ? "Cancel" : "Change image"}
                  </Button>
                  <Typography>{groomsmen.name}</Typography>
                  <CircularProgress
                    id={"bridalPartyNameChangeBuffer" + groomsmen.id}
                    sx={{ display: "none" }}
                  />
                  <Button onClick={() => handleEditName(groomsmen.id)}>
                    Edit name
                  </Button>
                  {groomsmen.editname && (
                    <Stack>
                      <TextField
                        size="small"
                        label="Change name"
                        variant="outlined"
                        value={editedBridalParty.name}
                        onChange={(e) => handleNameChange(groomsmen.id, e)}
                        sx={{ mb: 2, textAlign: "center" }}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave(groomsmen.id)}
                      >
                        Save
                      </Button>
                    </Stack>
                  )}

                  <IconButton
                    onClick={() => handleDeleteBridalParty(groomsmen.id)}
                  >
                    <DeleteIcon id={"bridalPartyDelete" + groomsmen.id} />
                    <CircularProgress
                      sx={{ display: "none" }}
                      id={"bridalPartyDeleteBuffer" + groomsmen.id}
                    />
                  </IconButton>
                </Box>
              );
            })}
            <Box
              id="groomsmanBox"
              sx={{
                padding: 1,
                display: "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </Stack>

          <Button onClick={handleAddGroomsmen}>Add Groomsmen</Button>
        </Box>
        <Box>
          <Typography variant="h5" gutterBottom>
            Bridesmaids
          </Typography>
          <Stack direction={isMobile ? "column" : "row"}>
            {bridesMaids.map((bridesmaid, index) => {
              return (
                <Box
                  sx={{
                    outline: "solid",
                    padding: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
     
                  }}
                >
                  <Avatar
                    src={bridesmaid.img}
                    alt={bridesmaid.name}
                    sx={{ width: 100, height: 100, mb: 2 }}
                  />

                  {bridesmaid.changeimg && (
                    <Stack direction="column">
                      {imgUploadProgress > 0 && imgUploadProgress < 100 && (
                        <p>{imgUploadProgress}%</p>
                      )}
                      <TextField
                        size="small"
                        variant="standard"
                        sx={{ mb: 2, textAlign: "center"}}
                        type="file"
                        onChange={(event) =>
                          handleImgChange(bridesmaid.id, event)
                        }
                      />
                      <Button id={bridesmaid.id} onClick={handleImageUpload}>
                        Upload
                      </Button>
                    </Stack>
                  )}
                  <Button onClick={() => handleEditImage(bridesmaid.id)}>
                    {bridesmaid.changeimg ? "Cancel" : "Change image"}
                  </Button>
                  <Typography>{bridesmaid.name}</Typography>
                  <Button onClick={() => handleEditName(bridesmaid.id)}>
                    Edit name
                  </Button>
                  {bridesmaid.editname && (
                    <Stack>
                      <TextField
                        size="small"
                        label="Change name"
                        variant="outlined"
                        value={editedBridalParty.name}
                        onChange={(e) => handleNameChange(bridesmaid.id, e)}
                        sx={{ mb: 2, textAlign: "center" }}
                      />
                      <CircularProgress
                        sx={{ display: "none" }}
                        id={"bridalPartyNameChangeBuffer" + bridesmaid.id}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave(bridesmaid.id)}
                      >
                        Save
                      </Button>
                    </Stack>
                  )}
                  <CircularProgress
                    id={"bridalPartyNameChangeBuffer" + bridesmaid.id}
                    sx={{ display: "none" }}
                  />
                  <IconButton
                    onClick={() => handleDeleteBridalParty(bridesmaid.id)}
                  >
                    <DeleteIcon id={"bridalPartyDelete" + bridesmaid.id} />
                    <CircularProgress
                    id={"bridalPartyDeleteBuffer" + bridesmaid.id}
                    sx={{ display: "none" }}
                  />
                  </IconButton>
                </Box>
              );
            })}
            <Box
              id="bridesmaidsBox"
              sx={{
                padding: 1,
                display: "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </Stack>
        </Box>

        <Button onClick={handleAddBridesmaids}>Add Bridesmaids</Button>
      </Container>
    </Box>
  );
};

export default BridalParty;
