import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export default function PageNotFound() {
    return (
    <div>404 Not Found

        <Link to="/">Home</Link>
    </div>
    );
}