import React, { useRef, useState, useEffect } from "react";
import {
  LoadScript,
  Autocomplete,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import TextField from "@mui/material/TextField";
import { Typography, Stack, Button, Box } from "@mui/material";
import axios from "axios";
import { server_url, eventInfoAPI, db_name } from "../../api";
import { isMobileDevice } from "../DeviceRecognition/DeviceRecognition";



const Googlemaps2 = () => {
  // CONSTANTS //
  const googleMapsAPIKey = "AIzaSyBJwuvigLPHJAGXnlMV2ea3T6WWZMSw9ZE";
  const libraries = ["places"];

  //USE REF
  const autocompleteRefs = useRef([]);
  //USE STATES //
  const [ceremLocation, setCeremLocation] = useState({
    lat: -34.397,
    lng: 150.644,
  });
  const [recepLocation, setRecepLocation] = useState({
    lat: -34.397,
    lng: 150.644,
  });
  const [map, setMap] = useState(null);
  const [eventInfo, setEventInfo] = useState({
    db_name: db_name,
    ceremony_location: "",
    reception_location: "",
    husband_name: "",
    wife_name: "",
    event_date: "",
    editCermLoc: true,
    editRecepLoc: true,
    editHusbandName: true,
    editWifeName: true,
    editEventDate: true,
  });
  const [dataBaseTableEmpty, setDataBaseTableEmpty] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  //HANDLE CHANGE IN LOCATION ENTERED
  const handlePlaceChanged = (index) => {
    const locationType = ["ceremony_location", "reception_location"];
    const autocomplete = autocompleteRefs.current[index];
    const place = autocomplete.getPlace();
    //const inputId = autocomplete.inputId; // Access the TextField's input ID
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      if (index === 0) {
        setCeremLocation({ lat, lng });
      } else {
        setRecepLocation({ lat, lng });
      }

      setEventInfo((prevVal) => ({
        ...prevVal,
        [locationType[Number(index)]]: place.formatted_address,
      }));
    }
    console.log("TextField ID:", locationType[Number(index)]);
    console.log("Selected Place:", place.formatted_address);
  };
  // AFTER GOOGLE MAP API HAS LOADED
  const onLoad = (autocomplete, index) => {
    autocompleteRefs.current[index] = autocomplete;
    // Access the input element directly through the DOM
    const inputElement = document.getElementById(`textfield${index + 1}`);
    if (inputElement) {
      autocomplete.inputId = inputElement.id; // Save the TextField's input ID to the Autocomplete instance
    }
  };
  const onMapLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  // HANDLE BUTTON CLICKS
  const handleSave = (event) => {
    if (dataBaseTableEmpty) {
      const firstEntry = {
        db_name: db_name,
        ceremony_location: eventInfo["ceremony_location"],
        reception_location: eventInfo["reception_location"],
        husband_name: "",
        wife_name: "",
        event_date: "",
        editCermLoc: true,
        editRecepLoc: true,
        editHusbandName: true,
        editWifeName: true,
        editEventDate: true,
      };
      console.log("First entry added: ", firstEntry);
      addEventInfo(firstEntry);
    } else {
      const columnEdit = event.target.id;
      if (columnEdit === "ceremony_location") {
        editEventInfo(columnEdit, eventInfo["ceremony_location"]);
        console.log(
          "Ceremony Location entry added: ",
          eventInfo["ceremony_location"]
        );
      } else if (columnEdit === "reception_location") {
        editEventInfo(columnEdit, eventInfo["reception_location"]);
        console.log(
          "Reception Location entry added: ",
          eventInfo["reception_location"]
        );
      }
    }
    //turning edit mode off
    setEventInfo((prevVal) => ({
      ...prevVal,
      ["editCermLoc"]: false,
      ["editRecepLoc"]: false,
      ["editHusbandName"]: false,
      ["editWifeName"]: false,
      ["editEventDate"]: false,
    }));
  };
  // Handle Edit location button
  const handleEdit = (event) => {
    const editColumn = event.target.id;
    setEventInfo((prevVal) => ({
      ...prevVal,
      [editColumn]: true,
    }));
  };

  //API REQUESTS
  const getEventInfo = () => {
    axios
      .get(`${server_url+eventInfoAPI + '?db_name='+db_name}`, {
        timeout: 10000, // Timeout set to 10 seconds
      })
      .then((response) => {
        //debugger;
        const data = response.data;
        console.log("Event info API: ", data);
        if (data.length === 0) {
          setDataBaseTableEmpty(true);
          
        } else {
          setDataBaseTableEmpty(false);
          setEventInfo({
            db_name: db_name,
            ceremony_location: data[0].ceremony_location,
            reception_location: data[0].reception_location,
            husband_name: data[0].husband_name,
            wife_name: data[0].wife_name,
            event_date: data[0].event_date,
            editCermLoc: false,
            editRecepLoc: false,
            editHusbandName: false,
            editWifeName: false,
            editEventDate: false,
          })
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the entries!", error);
      });
  };
  const editEventInfo = (columnName, eventInfo) => {
    axios
      .put(
        server_url + eventInfoAPI + "/" + columnName,
        {
          new_value: eventInfo,
          db_name: db_name
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Data: ", response);
        getEventInfo();
      })
      .catch((error) => {
        console.error("Error uploading file", error);
      });
  };
  const addEventInfo = (eventInfo) => {
    //debugger;
    axios
      .post(server_url + eventInfoAPI, eventInfo)
      .then((response) => {
        console.log("Response Data: ", response);
        getEventInfo();
      })
      .catch((error) => {
        console.error("Error uploading file", error);
      });
  };

  //USE EFFECTS //
  useEffect(() => {
    getEventInfo();
    setIsMobile(isMobileDevice());
  }, []);
  useEffect(() => {
    console.log("Event info use state", eventInfo);
  }, [eventInfo]);

  return (
    <LoadScript googleMapsApiKey={googleMapsAPIKey} libraries={libraries}>
      <div>
        <Typography padding={2} 
          variant='h5'
        >Ceremony Location</Typography>
        {eventInfo["editCermLoc"] ? (
          <Autocomplete
            onLoad={(autocomplete) => onLoad(autocomplete, 0)}
            onPlaceChanged={() => handlePlaceChanged(0)}
          >
            <Stack direction="row">
              <TextField
                id="ceremony_location"
                label="Enter Ceremony Location"
                variant="outlined"
                fullWidth
              />
              <Button onClick={handleSave} id="ceremony_location">
                Save
              </Button>
            </Stack>
          </Autocomplete>
        ) : (
          <Stack direction="row">
            <Typography>{eventInfo["ceremony_location"]}</Typography>
            <Button onClick={handleEdit} id="editCermLoc">
              Edit
            </Button>
          </Stack>
        )}

        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            padding: 3,
            marginBottom: "7vh",
          }}
          center={ceremLocation}
          zoom={15}
          onLoad={onMapLoad}
        >
          <Marker position={ceremLocation} />
        </GoogleMap>

        <Typography padding={2} 
        variant='h5'
        >Reception Location</Typography>
        {eventInfo.editRecepLoc ? (
          <Autocomplete
            onLoad={(autocomplete) => onLoad(autocomplete, 1)}
            onPlaceChanged={() => handlePlaceChanged(1)}
          >
            <Stack direction="row">
              <TextField
                id="reception_location"
                label="Enter Reception Location"
                variant="outlined"
                fullWidth
              />
              <Button onClick={handleSave} id="reception_location">
                Save
              </Button>
            </Stack>
          </Autocomplete>
        ) : (
          <Stack direction="row">
            <Typography> {eventInfo.reception_location} </Typography>
            <Button onClick={handleEdit} id="editRecepLoc">
              Edit
            </Button>
          </Stack>
        )}

        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            padding: 3,
            marginBottom: "10vh",
          }}
          center={recepLocation}
          zoom={15}
          onLoad={onMapLoad}
        >
          <Marker position={recepLocation} />
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default Googlemaps2;
